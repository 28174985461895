import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import DashboardHeader from 'generic/components/ui/DashboardHeader';

const DashboardHeaderContainer = () => {
  const hasReport = useSelector((state) => state.config.user.hasReport);

  const loading = useSelector((state) => {
    const widgets = _.get(state, 'dashboard.widgets', {});
    return _.isEmpty(widgets);
  });

  return (
    <DashboardHeader loading={loading} hasReport={hasReport} />
  );
};

export default DashboardHeaderContainer;
