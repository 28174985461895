export const types = {
  CHANGE_ACTIVE_BASE: '@CONFIG/CHANGE_ACTIVE_BASE',
  SET_ACTIVE_BASE: '@CONFIG/SET_ACTIVE_BASE',
  CLEANUP_CONFIG: '@CONFIG/CLEANUP_CONFIG',
  SET_CONFIG: '@CONFIG/SET_CONFIG',
  SET_FORM_FIELDS: '@CONFIG/SET_FORM_FIELDS',
  RESET_FORM_FIELDS: '@CONFIG/RESET_FORM_FIELDS',
  // Actions sur le user
  UPLOAD_USER_IMAGE: '@CONFIG/UPLOAD_USER_IMAGE',
  UPLOAD_USER_IMAGE_SUCCESS: '@CONFIG/UPLOAD_USER_IMAGE_SUCCESS',
  UPLOAD_USER_IMAGE_ERROR: '@CONFIG/UPLOAD_USER_IMAGE_ERROR',
  UPLOAD_USER_IMAGE_CLOSE_DIALOG: '@CONFIG/UPLOAD_USER_IMAGE_CLOSE_DIALOG',
  SAVE_USER_IMAGE: '@CONFIG/SAVE_USER_IMAGE',
  SAVE_USER_IMAGE_SUCCESS: '@CONFIG/SAVE_USER_IMAGE_SUCCESS',
  SAVE_USER_IMAGE_ERROR: '@CONFIG/SAVE_USER_IMAGE_ERROR',
  SAVE_PROFILE: '@CONFIG/SAVE_PROFILE',
  SAVE_PROFILE_SUCCESS: '@CONFIG/SAVE_PROFILE_SUCCESS',
  SAVE_PROFILE_ERROR: '@CONFIG/SAVE_PROFILE_ERROR',
};

export const cleanupConfig = () => ({
  type: types.CLEANUP_CONFIG,
});

export const setConfig = (config, user) => ({
  type: types.SET_CONFIG,
  config,
  user,
});

export const changeActiveBase = (baseId) => ({
  type: types.CHANGE_ACTIVE_BASE,
  baseId,
});

export const setActiveBase = (base) => ({
  type: types.SET_ACTIVE_BASE,
  base,
});

export const setFormFields = (fields) => ({
  type: types.SET_FORM_FIELDS,
  fields,
});

export const uploadUserImage = (file) => ({
  type: types.UPLOAD_USER_IMAGE,
  file,
});

export const uploadUserImageSuccess = (imageName) => ({
  type: types.UPLOAD_USER_IMAGE_SUCCESS,
  imageName,
});

export const uploadUserImageError = () => ({
  type: types.UPLOAD_USER_IMAGE_ERROR,
});

export const uploadUserImageCloseDialog = () => ({
  type: types.UPLOAD_USER_IMAGE_CLOSE_DIALOG,
});

export const saveUserImage = (params) => ({
  type: types.SAVE_USER_IMAGE,
  params,
});

export const saveUserImageSuccess = (user) => ({
  type: types.SAVE_USER_IMAGE_SUCCESS,
  user,
});

export const saveUserImageError = () => ({
  type: types.SAVE_USER_IMAGE_ERROR,
});

export const saveProfile = (params) => ({
  type: types.SAVE_PROFILE,
  params,
});

export const saveProfileSuccess = (user) => ({
  type: types.SAVE_PROFILE_SUCCESS,
  user,
});

export const saveProfileError = () => ({
  type: types.SAVE_PROFILE_ERROR,
});

export const resetFormFields = () => ({
  type: types.RESET_FORM_FIELDS,
});
