import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsWordcloud from 'highcharts/modules/wordcloud';

const archimedeanSpiral = function archimedeanSpiral(t) {
  const newT = t * 0.35;

  return {
    x: newT * Math.cos(newT) * 1.2,
    y: newT * Math.sin(newT),
  };
};

HighchartsNoData(Highcharts);
HighchartsWordcloud(Highcharts);
Highcharts.seriesTypes.wordcloud.prototype.spirals.archimedeanSpiral = archimedeanSpiral;

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      series: {
        animation: false,
      },
      wordcloud: {
        minFontSize: 10,
        rotation: {
          from: 0,
          to: 0,
        },
        spiral: 'archimedeanSpiral',
        allowExtendPlayingField: true,
        placementStrategy: 'center',
        cursor: 'pointer',
      },
    },
    responsive: {
      rules: [{
        condition: {
          minWidth: 700,
        },
        chartOptions: {
          plotOptions: {
            series: {
              animation: false,
            },
            wordcloud: {
              spiral: 'rectangular',
            },
          },
        },
      }],
    },
    chart: { type: 'wordcloud' },
  },
};

const Wordcloud = ({ highchartsOptions, containerId }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const chartRef = useRef();
  useEffect(() => {
    let resizeObserver;
    if (containerId) {
      const { chart } = chartRef.current;

      resizeObserver = new ResizeObserver(() => {
        if (!_.isEmpty(chart)) {
          chart.reflow();
        }
      });

      resizeObserver.observe(document.getElementById(containerId));
    }
    return () => {
      if (resizeObserver) {
        const el = document.getElementById(containerId);
        if (el) {
          resizeObserver.unobserve(el);
        } else {
          resizeObserver.disconnect();
        }
      }
    };
  }, [containerId]);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
      ref={chartRef}
    />
  );
};

Wordcloud.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  containerId: PropTypes.string,
};

Wordcloud.defaultProps = {
  containerId: null,
};

export default Wordcloud;
