import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
} from '@mui/material';
import Highcharts from 'highcharts';
import { OpenWith } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import THEME_CONFIG from 'generic/core/theme';

const DashboardWidget = ({
  highchartsOptions, handleExportWidget, componentProps, component: Component, flat, draggable,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  Highcharts.setOptions(theme.palette.mode === 'dark' ? THEME_CONFIG.DARK.HIGHCHARTS : THEME_CONFIG.LIGHT.HIGHCHARTS);

  const menuItems = [
    'viewFullscreen', 'printChart', 'separator',
    'downloadJPEG', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator',
    'downloadXLS', 'downloadCSV',
  ];
  if (handleExportWidget) {
    menuItems.push('downloadAllCSV');
  }
  const globalHighChartsOptions = {
    options: {
      accessibility: { enabled: false },
      credits: { enabled: false },
      exporting: {
        menuItemDefinitions: {
          downloadAllCSV: {
            onclick: handleExportWidget,
            text: t('dashboard.download_all_csv'),
          },
        },
        buttons: {
          contextButton: {
            symbolStrokeWidth: 2,
            menuItems,
          },
        },
      },
    },
  };
  const finalOptions = _.merge({}, globalHighChartsOptions, highchartsOptions);

  const cardSx = {};
  if (theme.palette.mode === 'dark') {
    cardSx.border = '1px solid rgb(255 255 255 / 30%)';
    cardSx.boxShadow = 'none';
  }
  return (
    <Box height="100%" width="100%">
      {flat ? (
        <Box>
          {Component == null
            ? ''
            : (
              <Component
                highchartsOptions={finalOptions}
                handleExportWidget={handleExportWidget}
                {...componentProps}
              />
            )}
        </Box>
      ) : (
        <Card
          sx={{
            width: '100%',
            height: '100%',
            ...cardSx,
          }}
        >
          <Box
            position="relative"
            height="calc(100% - 10px)"
            px={2}
            pt={0.5}
          >
            {draggable && (
              <Box
                className="drag-handle"
                position="absolute"
                zIndex="1300"
                top="15px"
                sx={{ cursor: 'move' }}
              >
                <OpenWith sx={{ userSelect: 'all', fontSize: '20px', color: 'rgb(102, 102, 102)' }} />
              </Box>
            )}
            {Component == null
              ? ''
              : (
                <Component
                  highchartsOptions={finalOptions}
                  handleExportWidget={handleExportWidget}
                  {...componentProps}
                />
              )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

DashboardWidget.propTypes = {
  component: PropTypes.elementType,
  componentProps: PropTypes.shape(),
  highchartsOptions: PropTypes.shape(),
  flat: PropTypes.bool,
  handleExportWidget: PropTypes.func,
  draggable: PropTypes.bool,
};

DashboardWidget.defaultProps = {
  component: null,
  highchartsOptions: {},
  componentProps: {},
  flat: false,
  handleExportWidget: null,
  draggable: false,
};

export default DashboardWidget;
