import { get } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const searchFacet = ({
  baseId,
  facetmax = 10,
  facetmax2 = 10,
  facets,
  list,
  mindoccount = 1,
  query,
  exportWidget = false,
  totalunique = false,
}) => get({
  path: '/elastic/search-facet',
  uriParams: {
    base: baseId,
    mindoccount,
    facetmax,
    facetmax2,
    facets,
    list,
    query,
    export: exportWidget,
    totalunique,
  },
}).then(handleComplete);

const getNetworkGraphData = ({
  facets,
  relations,
  query,
  list,
  baseId,
  facetmax = 200,
  facetmax2 = 5,
  facetmax3 = 5,
  mindoccount = 1,
}) => get({
  path: '/elastic/search-graph',
  uriParams: {
    base: baseId,
    mindoccount,
    facetmax,
    facetmax2,
    facetmax3,
    facets,
    relations,
    list,
    query,
  },
}).then(handleComplete);

const searchResult = ({
  baseId,
  slice,
  sort,
  query,
}) => get({
  path: '/elastic/search-result',
  uriParams: {
    base: baseId,
    slice,
    sort,
    query,
  },
}).then(handleComplete);

export {
  // eslint-disable-next-line import/prefer-default-export
  searchFacet,
  getNetworkGraphData,
  searchResult,
};
