import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    chart: { type: 'pie' },
    credits: { enabled: false },
    plotOptions: {
      series: {
        animation: false,
      },
      pie: {
        borderWidth: 0,
        showInLegend: false,
        size: '75%',
        innerSize: '60%',
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          connectorWidth: 1,
          distance: 20,
          softConnector: false,
          style: {
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    tooltip: {
      pointFormat: '<b>{point.y} ({point.percentage:.1f}%)</b>',
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                size: '60%',
                dataLabels: {
                  formatter() {
                    if (this.point.name.length > 20) {
                      return `${this.point.name.substring(0, 20)}...`;
                    }
                    return this.point.name;
                  },
                },
              },
            },
          },
        },
        {
          condition: {
            minWidth: 800,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                dataLabels: {
                  style: {
                    textOverflow: 'undefined',
                  },
                },
              },
            },
          },
        },
      ],
    },
    series: [{
      name: 'Data',
    }],
    title: { text: 'Titre Pie' },
  },
};

const Pie = ({ highchartsOptions, containerId }) => {
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const chartRef = useRef();
  useEffect(() => {
    let resizeObserver;
    if (containerId) {
      const { chart } = chartRef.current;

      resizeObserver = new ResizeObserver(() => {
        if (!_.isEmpty(chart)) {
          chart.reflow();
        }
      });

      resizeObserver.observe(document.getElementById(containerId));
    }
    return () => {
      if (resizeObserver) {
        const el = document.getElementById(containerId);
        if (el) {
          resizeObserver.unobserve(el);
        } else {
          resizeObserver.disconnect();
        }
      }
    };
  }, [containerId]);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
      ref={chartRef}
    />
  );
};

Pie.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  containerId: PropTypes.string,
};

Pie.defaultProps = {
  containerId: null,
};

export default Pie;
