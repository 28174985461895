import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isDate } from 'date-fns';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import * as Yup from 'yup';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { Link } from 'react-router-dom';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  Autocomplete,
  CheckboxWithLabel,
  TextField,
  Select,
  Switch,
  RadioGroup,
} from 'formik-mui';
import {
  DatePicker,
  TimePicker,
} from 'formik-mui-x-date-pickers';

import {
  Button,
  Box,
  TextField as MUITextField,
  Switch as MUISwitch,
  MenuItem,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Chip,
  Typography,
  Divider,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
  Radio,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add, ArrowBack, ArrowForward } from '@mui/icons-material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import FileUpload from 'generic/components/forms/FileUpload';
import AutocompleteAjax from 'generic/components/forms/AutocompleteAjax';
import TooltipButton from 'generic/components/ui/TooltipButton';
import PageBackAndTitle from 'generic/components/ui/PageBackAndTitle';
import { CONSTANTS } from 'generic/core/constants';
import { doSearch } from 'generic/api/search';
import { overrideRessource } from 'generic/utils/utils';
import { format } from 'generic/utils/dateUtils';

const FormGEDSideEffect = overrideRessource('components/forms/FormGEDSideEffect');

const { CK_EDITOR_CONFIG } = CONSTANTS;

const autocompleteRenderOption = (props, option, { inputValue }) => {
  const matches = match(option.libelle, inputValue, { insideWords: true });
  const parts = parse(option.libelle, matches);

  const highlightStyle = {
    fontWeight: 'bold',
  };

  return (
    <li {...props} key={option.code}>
      <div>
        {parts.map((part, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} style={part.highlight ? highlightStyle : {}}>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

const useStyles = makeStyles(() => ({
  rootForm: {
    width: '100%',
    display: 'flex',
    flexGrow: '1',
    '& .MuiGrid-item': {
      maxWidth: 'unset',
      flexGrow: '1',
    },
  },
}));

const FormGED = ({
  actionTypeUpload,
  actionTypeUploadError,
  actionTypeUploadSuccess,
  activeBaseId,
  onCloseDialog,
  currentTitle,
  fields,
  tabs,
  workflowActions,
  files,
  fileUploading,
  handleClear,
  handleClearUploadGEDFile,
  handleGoBack,
  handleToggleNewMultipleField,
  hasBackPage,
  initialValues,
  loading,
  multiple,
  nextDoc,
  onSubmit,
  previousDoc,
  ssPathForUpload,
  isLocked,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs[0] ? tabs[0].id : 0);
  const [fieldsExtraProps, setFieldsExtraProps] = useState({});
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  if (_.isEmpty(initialValues)) {
    return null;
  }

  const handleChangeTab = (__, idTab) => { setActiveTab(idTab); };

  /**
   * Méthode de render de chaque champ en fonction de sa nature
   */
  const renderField = (field, form) => {
    const finalFieldExtraProps = _.omit(fieldsExtraProps[field.name], ['forceMandatory']);
    switch (field.nature) {
      case 'html':
        return (
          <FormControl
            component="fieldset"
            className="formFieldset"
            fullWidth
          >
            <FormLabel component="legend">
              {field.libelle}
            </FormLabel>
            <Box m={1}>
              <CKEditor
                editor={ClassicEditor}
                name={field.name}
                data={form.values[field.name]}
                config={CK_EDITOR_CONFIG}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  form.setFieldValue(field.name, data);
                }}
              />
            </Box>
          </FormControl>
        );
      case 'print-html':
        // Champ "html" (RTE) en lecture seule
        return (
          <FormControl
            component="fieldset"
            className="formFieldset"
            fullWidth
          >
            <FormLabel component="legend">
              {field.libelle}
            </FormLabel>
            <Box m={1}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: form.values[field.name] }} />
            </Box>
          </FormControl>
        );
      case 'text':
      case 'print':
      case 'textarea':
        // Champs text/textarea classiques, 'print' produit un input text disabled
        return (
          <Field
            component={TextField}
            label={field.libelle}
            name={field.name}
            multiline={field.nature === 'textarea'}
            minRows={3}
            fullWidth
            disabled={field.nature === 'print'}
            {...finalFieldExtraProps}
          />
        );
      case 'fichier': {
        // Champ Fichier
        // On prépare les actions qui seront envoyées dans le dispatch
        // du composant FileUpload et lancées par la saga workUploadFile
        // dans core/files/sagas.js
        const uploadActions = {
          loading: {
            type: actionTypeUpload,
            fieldName: field.name,
          },
          success: {
            type: actionTypeUploadSuccess,
            fieldName: field.name,
          },
          error: {
            type: actionTypeUploadError,
            fieldName: field.name,
          },
        };
        // Cas particulier des champs fichier, on va prendre la valeur dans
        // le champ "files" de Redux, elle est à jour en fonction de la
        // présence ou non d'un fichier dans le champ
        const fileUploadName = _.get(files, field.name, form.values[field.name]);
        return (
          <Field
            component={FileUpload}
            name={field.name}
            fileUploadName={fileUploadName}
            label={field.libelle}
            uploadParams={{
              base: activeBaseId,
              base_mise_page: field.idmisepage,
              subpath: ssPathForUpload,
            }}
            fileUploading={fileUploading}
            uploadActions={uploadActions}
            handleClearField={() => handleClearUploadGEDFile(field.name)}
            withThumb={false}
            {...finalFieldExtraProps}
          />
        );
      }
      case 'ranking':
        return (
          <FormControl
            component="fieldset"
            className="formFieldset"
            disabled={_.get(finalFieldExtraProps, 'disabled', false)}
            sx={{ mt: 0 }}
          >
            <FormLabel component="legend">{field.libelle}</FormLabel>
            <Field
              component={RadioGroup}
              name={field.name}
              sx={{ ml: 2 }}
              row
            >
              {_.map(field.values, (option) => (
                <FormControlLabel
                  key={option.code}
                  value={option.code}
                  control={<Radio />}
                  label={option.libelle}
                />
              ))}
            </Field>
          </FormControl>
        );
      case 'liste':
        // Champ select classique
        return (
          <Field
            component={Select}
            name={field.name}
            label={field.libelle}
            sx={{ width: '100%' }}
            MenuProps={{
              disablePortal: true,
            }}
            {...finalFieldExtraProps}
          >
            {_.map(
              field.values,
              (value) => (
                <MenuItem
                  key={value.code}
                  value={value.code}
                >
                  {value.libelle}
                </MenuItem>
              ),
            )}
          </Field>
        );
      case 'listeChoix':
        // Champ Autocomplete multiple (produit une liste de "chips")
        // La liste de valeurs disponibles est un tableau d'objet, on
        // n'ajoute pas ce qu'on veut
        return (
          <Field
            component={Autocomplete}
            name={field.name}
            fullWidth
            options={field.values}
            getOptionLabel={(option) => option?.libelle || ''}
            onBlur={() => form.setFieldTouched(field.name)}
            multiple
            disableCloseOnSelect
            renderInput={(params) => (
              <MUITextField
                {...params}
                label={field.libelle}
              />
            )}
            renderOption={autocompleteRenderOption}
            {...finalFieldExtraProps}
          />
        );
      case 'autocomplete_text':
        // Champ Autocomplete "freeSolo" multiple (produit une liste de "chips")
        // La liste de valeurs est chargée en Ajax via api/elastic/search-autocomplete
        // Le champ doit disposer d'un attribut "facet" renvoyé par le back,
        // correspondant au champ associé dans elastic
        return (
          <Field
            component={AutocompleteAjax}
            name={field.name}
            facet={field.autocomplete}
            multiple
            freeSolo
            getOptionLabel={(option) => option}
            fullWidth
            textFieldProps={{
              label: field.libelle,
            }}
            {...finalFieldExtraProps}
          />
        );
      case 'chips':
        // Champ Autocomplete "freeSolo" multiple (produit une liste de "chips")
        // La liste de valeurs disponibles est vide, on ajoute ce qu'on veut
        return (
          <Field
            component={Autocomplete}
            name={field.name}
            options={field.values}
            multiple
            freeSolo
            onBlur={() => form.setFieldTouched(field.name)}
            fullWidth
            renderInput={(params) => (
              <MUITextField
                {...params}
                label={field.libelle}
              />
            )}
            renderOption={autocompleteRenderOption}
            {...finalFieldExtraProps}
          />
        );
      case 'chips-non-modifiable':
        // Liste de "chips" non modifiable
        return (
          <FormControl
            component="fieldset"
            className="formFieldset"
            fullWidth
          >
            <FormLabel component="legend">
              {field.libelle}
            </FormLabel>
            <Box ml={1} mr={1} mb={1}>
              {_.map(
                form.values[field.name],
                (value) => (
                  <Chip
                    sx={{ margin: '3px' }}
                    key={value}
                    label={value}
                  />
                ),
              )}
            </Box>
          </FormControl>
        );
      case 'liste_checkbox_ou':
      case 'liste_checkbox_et_ou':
        return (
          <FormControl
            component="fieldset"
            className="formFieldset"
            fullWidth
          >
            <FormLabel component="legend">
              <Box component="span" mr={2}>{field.libelle}</Box>
              {field.nature === 'liste_checkbox_et_ou' && (
                <FormControlLabel
                  control={(
                    <Field
                      component={Switch}
                      size="small"
                      type="checkbox"
                      name={`${field.name}_comp`}
                      color="primary"
                      onChange={(e) => {
                        form.setFieldValue(
                          `${field.name}_comp`,
                          e.target.checked ? '1' : '',
                        );
                      }}
                    />
                  )}
                  label={t('form.checkbox.and')}
                />
              )}
              <FormControlLabel
                control={(
                  <MUISwitch
                    size="small"
                    type="checkbox"
                    color="primary"
                    onChange={(e) => {
                      form.setFieldValue(
                        field.name,
                        e.target.checked ? _.map(field.values, (item) => item.code) : [],
                      );
                    }}
                  />
                )}
                label={t('form.checkbox.all')}
              />
            </FormLabel>
            <Box ml={1}>
              <FormGroup row>
                {_.map(
                  field.values,
                  (item) => (
                    <Field
                      component={CheckboxWithLabel}
                      name={field.name}
                      key={item.code}
                      value={item.code}
                      Label={{ label: item.libelle }}
                      type="checkbox"
                      color="primary"
                    />
                  ),
                )}
              </FormGroup>
            </Box>
          </FormControl>
        );
      case 'date':
      case 'datetime': {
        // Champ date avec datepicker
        // OU Champ datetime avec datepicker et timepicker
        let fieldNameDate = field.name;
        let fieldNameTime;
        if (field.nature === 'datetime') {
          fieldNameDate = `DATE_${field.name}`;
          fieldNameTime = `TIME_${field.name}`;
        }
        return (
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            <Box
              width={210}
              mr={2}
            >
              <Field
                component={DatePicker}
                name={fieldNameDate}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    label={field.libelle}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t('form.datepicker.placeholder'),
                    }}
                    helperText={
                      !_.isEmpty(form.errors[fieldNameDate]) ? (
                        form.errors[fieldNameDate]
                      ) : null
                    }
                  />
                )}
              />
            </Box>
            {field.nature === 'datetime' && (
              <Box
                width={110}
              >
                <Field
                  component={TimePicker}
                  name={fieldNameTime}
                  inputFormat="HH:mm"
                />
              </Box>
            )}
          </Box>
        );
      }
      case 'liaison_externe': {
        const doAutocomplete = async ({ uriParams: { filtre: F_2 } }) => {
          const params = {
            bodyItems: {
              premier: 1,
              dernier: 5,
              base: field.idbaseliaison,
              champs: {
                F_2,
              },
            },
          };

          const docsOnly = (response) => response.documents;

          return doSearch(params)
            .then(docsOnly)
            .catch(() => []);
        };

        return (
          <Field
            component={AutocompleteAjax}
            name={field.name}
            highlight={false}
            doAutocompleteOverride={doAutocomplete}
            getOptionLabel={(option) => option?.titre || ''}
            isOptionEqualToValue={(op, val) => (op?.id === val)}
            onChange={(__, option) => {
              form.setFieldValue(field.name, option?.id);
            }}
            renderOption={(liProps, option) => (
              <li
                key={option.id}
                {...liProps}
              >
                {option.titre}
                &nbsp;
                &nbsp;
                &nbsp;
                {option?.description}
              </li>
            )}
            fullWidth
            textFieldProps={{
              label: field.libelle,
            }}
          />
        );
      }
      default:
        return '';
    }
  };

  /**
   * Méthode utile aux champs multiples, renvoie un booléen qui permet de disable ou non le bouton d'ajout d'un champ
   * supplémentaire. Pour chaque cas on vérifie si la valeur du champ courant est vide, ou si il comporte des erreurs
   * de validation
   */
  const isNewInputButtonDisabled = (form, field) => {
    if (field.nature === 'datetime') {
      // Pour les champs de nature 'datetime', c'est un cas particulier,
      // il y a deux valeurs à vérifier (deux inputs) : l'un dont le nom
      // commence par DATE_, le second par TIME_
      if ((!isDate(form.values[`DATE_${field.name}`]) || !isDate(form.values[`TIME_${field.name}`])
        || form.errors[`DATE_${field.name}`] || form.errors[`TIME_${field.name}`])) {
        return true;
      }
    } else if (_.isEmpty(form.values[field.name]) || form.errors[field.name]) {
      // Pour le reste, on vérifie si la valeur du champ courant est vide,
      // ou si il dispose d'une erreur associée dans Formik
      return true;
    }
    return false;
  };

  // On prépare ici le schema de validation Yup du formulaire
  const yupValidationSchema = {};
  _.each(fields, (field) => {
    // Si jamais le field est en isMandatory mais que le FormGEDSideEffect l'a disabled,
    // on ne force pas le required de Yup
    if (field.isMandatory || fieldsExtraProps[field.name]?.forceMandatory) {
      yupValidationSchema[field.name] = Yup.string().required(t('form.errors.mandatory'));
    } else if (['date', 'datetime'].includes(field.nature)) {
      const fieldName = field.nature === 'date' ? field.name : `DATE_${field.name}`;
      const minDate = new Date(1900, 1, 1);
      const maxDate = new Date(2100, 11, 31);
      yupValidationSchema[fieldName] = Yup.date().nullable()
        .min(minDate, `${t('form.errors.min_date_message')} ${format(minDate)}`)
        .max(maxDate, `${t('form.errors.max_date_message')} ${format(maxDate)}`)
        .typeError(t('form.errors.invalid_date'));
      if (field.nature === 'datetime') {
        yupValidationSchema[`TIME_${field.name}`] = Yup.date().nullable()
          .typeError(t('form.errors.invalid_hour'));
      }
    }
  });

  const generateActionsButtons = (form) => (
    <Box
      display="flex"
      gap={1}
      justifyContent={onCloseDialog ? 'flex-end' : 'center'}
    >
      {onCloseDialog && (
        <Button
          onClick={onCloseDialog}
          variant="text"
        >
          {t('ui.cancel')}
        </Button>
      )}
      <Button
        type="reset"
        variant="text"
        onClick={handleClear}
        disabled={isLocked}
      >
        {t('form.reset')}
      </Button>
      <LoadingButton
        type="submit"
        disabled={loading || !form.isValid || isLocked}
        loading={loading}
      >
        {t('form.save')}
      </LoadingButton>
      {!_.isEmpty(workflowActions) && (
        <Fragment>
          <Divider orientation="vertical" flexItem />
          <Box
            display="flex"
            gap={1}
            justifyContent="center"
            flexWrap="wrap"
          >
            {_.map(
              workflowActions,
              (action) => (
                <Button
                  key={action.etat}
                  disabled={loading || !form.isValid}
                  onClick={() => onSubmit(form.values, action.etat)}
                  color="secondary"
                >
                  {action.libelle}
                </Button>
              ),
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );

  const fieldsByTabs = _.groupBy(fields, 'idonglet');
  return (
    <Box
      {...props}
      flexGrow="1"
      width="100%"
      className="desktopOnlyOverflow"
      display="flex"
      flexDirection="column"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(yupValidationSchema)}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form
            className={clsx(classes.rootForm, 'desktopOnlyOverflow')}
          >
            <FormGEDSideEffect setFieldsExtraProps={setFieldsExtraProps} />
            <Box
              display="flex"
              flexDirection="column"
              flexGrow="1"
              width="100%"
            >
              <Box
                className="stickyHeader"
                borderBottom="1px solid"
                borderColor="divider"
                zIndex="1200"
              >
                <PageBackAndTitle
                  backBtnIsVisible={hasBackPage}
                  backBtnTitle={t('ui.back_to_results')}
                  handleGoBack={handleGoBack}
                  title={currentTitle}
                />
                {!_.isEmpty(tabs) && (
                  <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    sx={{ px: 1 }}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                  >
                    {_.map(
                      tabs,
                      (tab) => (
                        <Tab
                          label={tab.libelle}
                          key={tab.id}
                          value={tab.id}
                          id={`tab-${tab.id}`}
                          aria-controls={`tabpanel-${tab.id}`}
                        />
                      ),
                    )}
                  </Tabs>
                )}
              </Box>

              <Box className="GEDContent desktopOnlyOverflow" flexGrow="1">
                {_.map(
                  _.keys(fieldsByTabs),
                  (keyFieldsForATab) => (
                    <div
                      role="tabpanel"
                      hidden={activeTab !== parseInt(keyFieldsForATab, 10)}
                      key={keyFieldsForATab}
                      id={`tabpanel-${keyFieldsForATab}`}
                      aria-labelledby={`tab-${keyFieldsForATab}`}
                    >
                      <Grid
                        key={`grid-${keyFieldsForATab}`}
                        container
                        spacing={1}
                        overflow="auto"
                        p={1}
                      >
                        {_.map(
                          fieldsByTabs[keyFieldsForATab],
                          (field) => {
                            if (field.name && field.isVisible) {
                              const xs = field.xs > 0 ? field.xs : 12;
                              const md = field.md > 0 ? field.md : xs;
                              const lg = field.lg > 0 ? field.lg : md;

                              return (
                                <Grid
                                  key={`grid-${field.name}`}
                                  item
                                  xs={xs}
                                  md={md}
                                  lg={lg}
                                  mt={1}
                                  pl={2}
                                  pr={2}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexGrow="1"
                                >
                                  {renderField(field, form)}
                                  {field.isMultiple && field.nextFieldName && (
                                    <Box sx={{
                                      height: '100%',
                                      ml: 1,
                                      pt: 1,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                    >
                                      <TooltipButton
                                        tag="fab"
                                        size="extraSmall"
                                        color="primary"
                                        onClick={() => handleToggleNewMultipleField(field.name)}
                                        disabled={isNewInputButtonDisabled(form, field)}
                                        title={`${t('form.add_new_field')} "${field.libelle}"`}
                                      >
                                        <Add />
                                      </TooltipButton>
                                    </Box>
                                  )}
                                </Grid>
                              );
                            }
                            return null;
                          },
                        )}
                      </Grid>
                    </div>
                  ),
                )}
              </Box>

              <Box
                className="bottomBtnsContainer"
              >
                {generateActionsButtons(form)}

                {multiple && (
                  <Box
                    mt={1}
                    display="flex"
                    flexWrap="nowrap"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>
                      {previousDoc && (
                        <Button
                          component={Link}
                          variant="text"
                          size="extraSmall"
                          to={`/ged/${previousDoc.base}/${encodeURIComponent(previousDoc.idext)}`}
                          startIcon={<ArrowBack />}
                          style={{ textTransform: 'none' }}
                        >
                          {t('ged.prev_doc')}
                          {!smallerThanMedium && (
                            <Typography
                              maxWidth="250px"
                              noWrap
                              fontStyle="italic"
                            >
                              &nbsp;
                              {previousDoc.title}
                            </Typography>
                          )}
                        </Button>
                      )}
                    </div>
                    <div>
                      {nextDoc && (
                        <Button
                          component={Link}
                          variant="text"
                          size="extraSmall"
                          to={`/ged/${nextDoc.base}/${encodeURIComponent(nextDoc.idext)}`}
                          endIcon={<ArrowForward />}
                          style={{ textTransform: 'none' }}
                        >
                          {t('ged.next_doc')}
                          {!smallerThanMedium && (
                            <Typography
                              maxWidth="250px"
                              noWrap
                              fontStyle="italic"
                            >
                              &nbsp;
                              {nextDoc.title}
                            </Typography>
                          )}
                        </Button>
                      )}
                    </div>
                  </Box>
                )}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

FormGED.propTypes = {
  actionTypeUpload: PropTypes.string.isRequired,
  actionTypeUploadError: PropTypes.string.isRequired,
  actionTypeUploadSuccess: PropTypes.string.isRequired,
  activeBaseId: PropTypes.number.isRequired,
  currentTitle: PropTypes.string,
  onCloseDialog: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      libelle: PropTypes.string,
      idonglet: PropTypes.number,
    }),
  ),
  files: PropTypes.shape(),
  fileUploading: PropTypes.bool,
  handleClear: PropTypes.func.isRequired,
  handleClearUploadGEDFile: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleToggleNewMultipleField: PropTypes.func.isRequired,
  hasBackPage: PropTypes.bool,
  initialValues: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  multiple: PropTypes.bool,
  nextDoc: PropTypes.shape({
    idext: PropTypes.string,
    title: PropTypes.string,
    base: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  previousDoc: PropTypes.shape({
    idext: PropTypes.string,
    title: PropTypes.string,
    base: PropTypes.number,
  }),
  ssPathForUpload: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      libelle: PropTypes.string,
    }),
  ),
  workflowActions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      libelle: PropTypes.string,
      etat: PropTypes.string,
    }),
  ),
  isLocked: PropTypes.bool,
};

FormGED.defaultProps = {
  currentTitle: `[${i18next.t('document.title_undefined')}]`,
  fields: [],
  files: {},
  fileUploading: false,
  hasBackPage: false,
  initialValues: {},
  multiple: false,
  nextDoc: null,
  onCloseDialog: null,
  previousDoc: null,
  ssPathForUpload: '',
  tabs: [],
  workflowActions: [],
  isLocked: false,
};

export default FormGED;
