import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import i18next from 'i18next';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSunburst from 'highcharts/modules/sunburst';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);
HighchartsSunburst(Highcharts);

const Sunburst = ({ highchartsOptions, containerId }) => {
  const defaultOptions = {
    highcharts: Highcharts,
    options: {
      plotOptions: {
        series: {
          animation: false,
        },
      },
      chart: { type: 'sunburst' },
      credits: { enabled: false },
      colors: ['transparent'].concat(Highcharts.getOptions().colors),
      series: [{
        name: i18next.t('dashboard.root'),
        allowDrillToNode: true,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
          filter: {
            property: 'innerArcLength',
            operator: '>',
            value: 16,
          },
          rotationMode: 'circular',
        },
        levels: [{
          level: 1,
          levelIsConstant: false,
          dataLabels: {
            filter: {
              property: 'outerArcLength',
              operator: '>',
              value: 64,
            },
          },
        }, {
          level: 2,
          colorByPoint: true,
        },
        {
          level: 3,
          colorVariation: {
            key: 'brightness',
            to: -0.5,
          },
        }],
      }],
      title: { text: 'Titre Sunburst' },
    },
  };
  const finalConfig = _.merge({}, defaultOptions, highchartsOptions);

  const chartRef = useRef();
  useEffect(() => {
    let resizeObserver;
    if (containerId) {
      const { chart } = chartRef.current;

      resizeObserver = new ResizeObserver(() => {
        if (!_.isEmpty(chart)) {
          chart.reflow();
        }
      });

      resizeObserver.observe(document.getElementById(containerId));
    }
    return () => {
      if (resizeObserver) {
        const el = document.getElementById(containerId);
        if (el) {
          resizeObserver.unobserve(el);
        } else {
          resizeObserver.disconnect();
        }
      }
    };
  }, [containerId]);

  return (
    <HighchartsReact
      {...finalConfig}
      containerProps={{
        style: { width: '100%', height: '100%' },
      }}
      ref={chartRef}
    />
  );
};

Sunburst.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  containerId: PropTypes.string,
};

Sunburst.defaultProps = {
  containerId: null,
};

export default Sunburst;
